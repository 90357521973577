export const siteInfo = {
    phone: {
        text: '(310) 715-6815',
        link: 'tel:+13107156815',
    },
    email: {
        text: 'bidding.dpt@gmail.com',
        link: 'mailto:bidding.dpt@gmail.com',
    },
    address: {
        text: '13518 S. Normandie Ave, Gardena, CA 90249',
        link: 'https://maps.app.goo.gl/b7tcWoHD99SyLdqH8',
    },
    social: {
        google: 'https://www.google.com/search?q=american+cabinet+works&rlz=1C1CHBF_en-GBPK1096PK1096&oq=american+cabinet+works&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRg8MgYIAhBFGDwyBggDEEUYPNIBCDMyMDdqMGo3qAIAsAIA&#lrd=0x80c2b58a9af78a15:0x6c3b422c8e4b92b6,1,,,,',
    },
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: '/about-us/',
    },
    {
        text: 'projects',
        link: '/projects/',
    },
    {
        text: 'clients',
        link: '/clients/',
    },
    {
        text: 'ADFH',
        link: '/adfh/',
    },
    {
        text: 'FAQ’s',
        link: '/faq/',
    },
];

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: '/about-us/',
    },
    {
        text: 'clients',
        link: '/clients/',
    },
    {
        text: 'projects',
        link: '/projects/',
    },
    {
        text: 'FAQ’s',
        link: '/faq/',
    },
    {
        text: 'privacy policy',
        link: '/privacy-policy/',
    },
];

export function getNavLink(text: string) {
    const linkObj = flatLinks.find(data => data.text.toLowerCase() === text.toLowerCase());

    if (linkObj) return linkObj.link;
    else return '';
}

const flatLinks = (() => {
    const flat: NavLinksTypes = [];

    const pushFlat = (arr: NavLinksTypes) =>
        arr.forEach(data => {
            if (!data.links) flat.push(data);
            else {
                if (data.link) {
                    flat.push({
                        text: data.text,
                        link: data.link,
                    });
                }
                pushFlat(data.links);
            }
        });

    pushFlat(navLinks);
    return flat;
})();
