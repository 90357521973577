import { css, Global, Theme } from '@emotion/react';
import '@fontsource/fira-sans/500.css';
import '@fontsource/fira-sans/700.css';
import '@fontsource/montserrat';
import '@fontsource/montserrat/600.css';
import emotionNormalize from 'emotion-normalize';
import { h1, h2, h3, h4, h5 } from './Typography';

export const theme: Theme = {
    colors: {
        gray1: '#fff',
        gray2: '#F9F9F9',
        gray3: '#F4F4F4',
        gray4: '#B5B6B6',
        gray5: '#585656',
        gray6: '#333333',
        gray7: '#151414',
        red1: '#ECE3E6',
        red2: '#B31942',
        red3: '#501122',
        blue1: '#DDE6F2',
        blue2: '#4894F1',
        blue3: '#2C77D2',
        blue4: '#0A3161',
        blue5: '#0A1728',
    },
    spacing: {
        md: '2.22vw', //32px at 1440px wide viewport
        lg: '4.45vw', //64px ^^
        xl: '6.67vw', //96px ^^
        xxl: '8.89vw', //128px ^^
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={() => css`
                ${emotionNormalize};

                html {
                    font-size: 100%;
                    scroll-behavior: smooth;
                }
                body {
                    color: ${theme.colors.gray7};
                    font-family: 'Montserrat', sans-serif;
                    font-weight: normal;
                    font-size: 1rem;
                    line-height: 152.25%;
                    word-wrap: break-word;
                    font-kerning: normal;
                    word-wrap: break-word;
                    -webkit-font-smoothing: antialiased;
                }

                h1 {
                    ${h1};
                }

                h2 {
                    ${h2};
                }

                h3 {
                    ${h3};
                }

                h4 {
                    ${h4};
                }

                h5 {
                    ${h5};
                }

                a {
                    text-decoration: none;
                    color: ${theme.colors.gray7};
                    outline: none;
                    background-color: transparent;
                    -webkit-text-decoration-skip: objects;
                }

                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
                }

                ul,
                ol {
                    padding-inline-start: 24px;
                }

                button {
                    :disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                }

                @media (min-width: 1280px) {
                    body {
                        font-size: 1.125rem;
                    }
                }
            `}
        />
    );
};
