import { CLink } from '@/CLink';
import { Error, Form, Input, Label, Thanks } from '@/FormComponents';
import { PlainBtn, PrimaryBtn } from '@/buttons';
import { ReactComponent as ArrowIcon } from '@a/icons/right-arrow.svg';
import styled from '@emotion/styled';
import { useState } from 'react';
import { HomeLogo } from './Nav';
import { footerNavLinks } from './NavLinks';
import { redTxt } from './styles/Typography';
import { flexCenter, flexSpace, flexStart, npx, px } from './styles/classes';

const Section = styled.footer`
    ${px};
    padding-top: 40px;
    padding-bottom: 40px;

    @media (min-width: 768px) {
        padding-bottom: 0;
    }

    @media (min-width: 1280px) {
        display: grid;
        padding-top: 0;
        gap: 64px;
        row-gap: 0;
        max-width: 1905px;
        margin: 0 auto;
        place-items: center;
        grid-template-areas:
            'a b c'
            'd d d';
    }
`;

const FirstBox = styled.div`
    margin-bottom: 32px;
    max-width: 415px;
    grid-area: a;

    > p {
        :first-of-type {
            margin: 24px 0 16px;
        }

        :last-of-type {
            margin: 0;

            span {
                display: block;
                margin: 8px 0 0;
            }
        }
    }

    @media (min-width: 1280px) {
        grid-area: a;
        margin-bottom: 0;
        padding: 40px 40px 64px 0;
        border-right: 1px solid ${({ theme }) => theme.colors.gray3};
    }
`;

const Links = styled.div`
    ${flexSpace};
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 32px;
    max-width: 360px;
    gap: 32px;

    > div {
        ${flexStart};
        flex-direction: column;

        > h5 {
            margin: 0;
            color: ${({ theme }) => theme.colors.gray4};
        }

        > a {
            transition: color 0.3s ease-in-out;
            margin-top: 16px;
            text-transform: capitalize;

            :hover,
            :focus {
                color: ${({ theme }) => theme.colors.blue3};
            }
        }
    }

    @media (min-width: 1280px) {
        grid-area: b;
        margin-bottom: 0;
        width: 360px;
    }
`;

const Newsletter = styled.div`
    max-width: 360px;

    > h5 {
        margin: 0;
        color: ${({ theme }) => theme.colors.gray4};
    }

    > form {
        ${flexStart};
        align-items: stretch;
        position: relative;

        > label {
            margin: 0;

            span {
                border: 0;
                clip: rect(0 0 0 0);
                height: 1px;
                width: 1px;
                margin: -1px;
                padding: 0;
                overflow: hidden;
                position: absolute;
            }

            > input {
                padding: 18px;
            }
        }

        > button {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    @media (min-width: 1280px) {
        grid-area: c;
        padding-bottom: 12px;
        flex-shrink: 3;
    }
`;

const Bottom = styled.div`
    ${flexCenter};
    flex-direction: column;
    align-items: stretch;
    margin-top: 48px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray3};

    > button {
        margin: 0 -16px;
        background-color: ${({ theme }) => theme.colors.gray7};
        padding: 21px;

        > svg {
            transform: rotate(-90deg);
        }
    }

    > p {
        ${redTxt};
        color: ${({ theme }) => theme.colors.gray4};
        margin: 24px 0 0;
        text-align: center;
    }

    @media (min-width: 768px) {
        ${npx};
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: ${({ theme }) => theme.spacing.md};

        > p {
            text-align: left;
            order: -1;
            margin: 0;
        }

        > button {
            margin: 0;
        }
    }

    @media (min-width: 1280px) {
        grid-area: d;
        margin-top: 0;
        justify-self: stretch;
    }
`;

export const Footer = () => {
    const [submit, setSubmit] = useState<'init' | 'submitting' | 'submitted' | 'error'>('init');

    const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmit('submitting');

        const data = Object.fromEntries(new FormData(e.currentTarget));

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(() => {
                setSubmit('submitted');
                e.target.reset();
            })
            .catch(() => {
                setSubmit('error');
            });
    };
    return (
        <Section>
            <FirstBox>
                <HomeLogo />
                <p>
                    Explore our tailored solutions for office and home, crafted with precision and
                    enduring elegance.
                </p>
                <p>
                    Office Timings: <span />
                    Monday - Friday, 7:00 AM - 4:30 PM
                </p>
            </FirstBox>
            <Links>
                <div>
                    <h5>ACW</h5>
                    {footerNavLinks.slice(0, 3).map((data, i) => (
                        <CLink key={i} to={data.link}>
                            {data.text}
                        </CLink>
                    ))}
                </div>
                <div>
                    <h5>Help</h5>
                    {footerNavLinks.slice(3).map((data, i) => (
                        <CLink key={i} to={data.link}>
                            {data.text}
                        </CLink>
                    ))}
                </div>
            </Links>
            <Newsletter>
                <h5>Newsletter</h5>
                <p>Stay in the loop with our monthly newsletter!</p>
                <Form onSubmit={handleSubmit} submit={submit === 'submitted' || submit === 'error'}>
                    <Label htmlFor="email">
                        <span>Email address</span>
                        <Input
                            type="email"
                            id="email"
                            name="email"
                            required
                            placeholder="example@gmail.com"
                        />
                    </Label>
                    <PrimaryBtn disabled={submit === 'submitting'} type="submit">
                        {submit === 'submitting' ? 'Sending...' : 'Submit'}
                    </PrimaryBtn>
                    <Thanks newsletter submit={submit === 'submitted'} setSubmit={setSubmit} />
                    <Error newsletter error={submit === 'error'} />
                </Form>
            </Newsletter>
            <Bottom>
                <PlainBtn
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }}
                >
                    <ArrowIcon />
                </PlainBtn>
                <p>© 2024 American Cabinet Works. All rights reserved</p>
            </Bottom>
        </Section>
    );
};
